import React, {  } from 'react';
import SiteLayoutEmbeded from '../../components/SiteLayoutEmbeded';
import { Link } from '@reach/router';
import { Button } from 'antd';
import { OidaComponent } from '../oida';

// @ts-ignore
import { backbutton } from './embeded.module.scss';


function Index() {
    return (
        <SiteLayoutEmbeded>
            <OidaComponent />
            <Link to="/embeded/"><Button icon="arrow-left" className={backbutton}>Tilbake</Button></Link>
        </SiteLayoutEmbeded>
    );
}

export default Index;
