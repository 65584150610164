import { Location } from '@reach/router';
import { Button } from 'antd';
import React, {  } from 'react';
import SiteLayout from '../components/SiteLayout';

// @ts-ignore
import OidaImage from '../../static/oida.inline.svg';

// @ts-ignore
import { backbutton, image, info } from './oida.module.scss';

import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from 'gatsby';

export function OidaComponent() {

    return (
        <>
            <p style={{ fontWeight: 400 }}>Oida..</p>
            <br />
            <p className={info}>
                Vi kan dessverre ikke aktivere din HAN-port. Vennligst kontroller at målernummer og postnummer er korrekt.
            </p>
        </>
    );
}

function Oida() {
    return (<Location>
        {({ location, navigate }) => (
            <SiteLayout location={location}>
                <OidaImage className={image} />
                <br />
                <h1>Aktiver HAN</h1>
                <OidaComponent />
                <Link to="/"><Button icon={<ArrowLeftOutlined />} className={backbutton}>Tilbake</Button></Link>
            </SiteLayout>
        )}
    </Location>);
}

export default Oida;
